body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    width: 100vw;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
    box-sizing: border-box;
}

.gradient-background {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(48, 207, 208);
    background: -moz-radial-gradient(
            circle,
            rgba(48, 207, 208, 1) 0%,
            rgba(51, 8, 103, 1) 80%
    );
    background: -webkit-radial-gradient(
            circle,
            rgba(48, 207, 208, 1) 0%,
            rgba(51, 8, 103, 1) 80%
    );
    background: radial-gradient(
            circle,
            rgba(48, 207, 208, 1) 0%,
            rgba(51, 8, 103, 1) 80%
    );
    background-size: 180% 180%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#30cfd0", endColorstr="#330867", GradientType=1);
    animation: gradient-animation 20s ease infinite;

}

@keyframes gradient-animation {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 150% 150%;
    }
    50% {
        background-position: 0% 100%;
    }
    75% {
        background-position: 150% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}
